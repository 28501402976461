<template>
  <b-card no-body class="border-primary text-center">
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0">
        Top Employees x Month
      </h5>
      <b-badge variant="light-primary">
        <feather-icon icon="ActivityIcon" />
      </b-badge>
      <!-- <small class="text-muted w-100">February 24, 2022</small> -->
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">Beverlys Ydrogo: 1.700,00 $</span>
        </li>
        <li>
          <span class="align-middle">Jane morita Pacheco: 1.700,00 $</span>
        </li>
        <li class="my-25">
          <span class="align-middle">Yannet del castillo: 700,00 $</span>
        </li>
        <li>
          <span class="align-middle">leidy mar: 500,00 $</span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        View More
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
}
</script>

<style></style>
