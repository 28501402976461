import {ref, watch, computed} from "@vue/composition-api";
import store from "@/store";

// Notification
import {useToast} from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useInvoicesList() {
  // Use toast
  const toast = useToast();

  const refInvoiceListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {key: "id", label: "#", sortable: true},
    {key: "nro_order_clover", label: "# POS ID", sortable: true},
    {key: "nro_pay_clover", label: "# Pay", sortable: true},
    {key: "invoiceStatus", sortable: true},
    {key: "customer", sortable: true},
    {
      key: "total_order",
      label: "total Order",
      sortable: true,
      formatter: (val) => `$${val}`,
    },
    {
      key: "total_tips",
      label: "total Tips",
      sortable: true,
      formatter: (val) => `$${val}`,
    },
    {key: "employee", label: "Employee Cashier", sortable: true},
    {key: "created", label: "ISSUED DATE", sortable: true},
    {key: "receipt"},
    {key: "actions"},
  ];
  const perPage = ref(10);
  const totalInvoices = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(false);
  const statusFilter = ref(null);

  const statusOptions = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    };
  });

  const refetchData = () => {
    refInvoiceListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchInvoices = (ctx, callback) => {
    const {officeEmployee_officeId, rolId} = JSON.parse(
      localStorage.getItem("userData")
    ).dataUser;

    store
      .dispatch("app-invoice/fetchInvoices", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        order_status:
          statusFilter.value == null ? null : statusFilter.value.value,
        rolId: rolId,
        officeId: officeEmployee_officeId,
      })
      .then((response) => {
        const {invoices, total} = response.data;

        callback(invoices);
        totalInvoices.value = total;
        const arrayOrderStatus = [];
        const setObj = new Set();

        invoices.map((data, item) => {
          if (!setObj.has(data.order_status)) {
            setObj.add(data.order_status, data);
            arrayOrderStatus.push({
              label: data.order_status,
              value: data.order_status,
              id: item,
            });
          }
        });

        statusOptions.value = arrayOrderStatus;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = (status) => {
    if (status === "Partial Payment")
      return {variant: "warning", icon: "PieChartIcon"};
    if (status === "true") return {variant: "success", icon: "CheckCircleIcon"};
    if (status === "Downloaded")
      return {variant: "info", icon: "ArrowDownCircleIcon"};
    if (status === "Draft") return {variant: "primary", icon: "SaveIcon"};
    if (status === "Sent") return {variant: "secondary", icon: "SendIcon"};
    if (status === "Past Due") return {variant: "danger", icon: "InfoIcon"};
    return {variant: "success", icon: "CheckCircleIcon"};
  };

  const resolveClientAvatarVariant = (status) => {
    if (status === "Partial Payment") return "primary";
    if (status === "Paid") return "danger";
    if (status === "Downloaded") return "secondary";
    if (status === "Draft") return "warning";
    if (status === "Sent") return "info";
    if (status === "Past Due") return "success";
    return "primary";
  };

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    statusOptions,
  };
}
